import { useState } from "react"
import { useLogin } from "../hooks/useLogin"
import {
  Button,
  Paper,
  TextField,
  Box,
  Typography,
  Container,
  Alert
} from "@mui/material";
import { useNavigate } from "react-router-dom"
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import jwtDecode from "jwt-decode";

import { useGauth } from "../hooks/useGauth"

const SignIn = () => {
  const btncolor = {
    backgroundColor: "#118080",
    color: "#fff",
  };
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [success, setSuccess] = useState(null)
  //const { login, error, isLoading, emptyFields } = useLogin()
  const { login, error, emptyFields } = useLogin()

  const { gAuthlogin } = useGauth()
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()

    login(email, password)
  }

  const handleLogin = async (credentialResponse) => {
    //console.log(credentialResponse)
    var userObject = jwtDecode(credentialResponse.credential);
    //let tmpgData = JSON.parse(userObject);

    // console.log(userObject)
    const guser = await gAuthlogin(userObject.given_name, userObject.family_name, userObject.email, userObject.sub, userObject.picture)
      
    if(guser == "Error") {
        setSuccess('Sorry, you cannot log in here. You are being redirected to Knome.')
        setTimeout(() => {
            window.location.href = "https://me.knome.health/"
        }, 5000)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "80vh",
        }}
      >
        <Typography sx={{ textAlign: "center", p: 2, py: 0 }} component="h3" variant="h3">Ayurmd</Typography>
        <Typography sx={{ textAlign: "center", p: 1, py: 0, pb: 2 }} component="h6" variant="h6">Arogya Portal</Typography>
        <Paper sx={{ p: 5 }}>

          <Typography sx={{ textAlign: "center" }} component="h1" variant="h5">
            Sign In
          </Typography>

          <Box noValidate sx={{ mt: 1 }}>
            <form className="login" onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                inputProps={{ className: emptyFields?.includes('email') ? 'error' : '' }}
              />
              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                inputProps={{ className: emptyFields?.includes('password') ? 'error' : '' }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={btncolor}
              // disabled={isLoading}
              >
                Sign In
              </Button>
            </form>
          </Box>

          {error && <Alert severity="error"><div className="error">{error}</div></Alert>}

          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <GoogleOAuthProvider
              //clientId={"667259907049-pje0jb7rrpke9jtil6uefc46pk2lv4sl.apps.googleusercontent.com"}
              clientId={"1050265659815-gd8vislgd70sr8c8sdbf94h58l5j3gg0.apps.googleusercontent.com"}
            >
              <GoogleLogin
                width="260px"
                size="large "
                onSuccess={(credentialResponse) => {
                  // console.log(credentialResponse);
                  handleLogin(credentialResponse);
                }}
                onError={() => {
                  console.log("Login Failed");
                  //handleFailure();
                }}
              />
            </GoogleOAuthProvider>
          </Box>
            
          {success && <Alert severity="warning">{success}</Alert>}
        </Paper>
      </Box>
    </Container>
  );
}
export default SignIn