import React, { useState } from 'react';

import {
    Button,
    Paper,
    TextField,
    Box,
    Typography,
    Container,
    Alert
} from "@mui/material";

import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { useRegister } from "../hooks/useRegister"

const SignUp = () => {

    const btncolor = {
        backgroundColor: "#118080",
        color: "#fff",
    };

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    //const { signup, error, isLoading, emptyFields } = useRegister()
    const { signup, error, emptyFields } = useRegister()

    const handleSubmit = async (event) => {
        event.preventDefault();
        const abc = await signup(firstname, lastname, email, password)
        if (abc) {
            setFirstname('')
            setLastname('')
            setEmail('')
            setPassword('')
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "80vh",
                }}
            >
                <Typography sx={{ textAlign: "center", p: 2, py: 0 }} component="h3" variant="h3">Ayurmd</Typography>
                <Typography sx={{ textAlign: "center", p: 1, py: 0, pb: 2 }} component="h6" variant="h6">Arogya Portal</Typography>
                <Paper sx={{ p: 5 }}>

                    <Typography sx={{ textAlign: "center" }} component="h1" variant="h5">
                        Sign Up
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstname"
                                    required
                                    fullWidth
                                    id="firstname"
                                    label="First Name"
                                    className={(emptyFields || '').includes('firstname') ? 'error' : ''}
                                    onChange={(e) => setFirstname(e.target.value)}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastname"
                                    label="Last Name"
                                    name="lastname"
                                    autoComplete="family-name"
                                    className={(emptyFields || '').includes('lastname') ? 'error' : ''}
                                    onChange={(e) => setLastname(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    className={(emptyFields || '').includes('email') ? 'error' : ''}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    className={(emptyFields || '').includes('password') ? 'error' : ''}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            //disabled={isLoading}
                            style={btncolor}
                        >
                            Sign Up
                        </Button>
                        {error && <Alert severity="error"><div className="error">{error}</div></Alert>}
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href="/signin" variant="body2">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
}

export default SignUp