import { useAuthContext } from "./useAuthContext"

export const useGauth = () => {
    const { dispatch } = useAuthContext()

    const gAuthlogin = async (firstname, lastname, email, userid, pPic) => {

        // console.log(firstname, lastname, email, userid)

        const response = await fetch('/server/patient/gAuthLogin', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ firstname, lastname, email, userid })
        })
        const json = await response.json()

        if (response.ok) {
            // save the patient to local storage (i.e., save jwt in browser)
            localStorage.setItem('patient', JSON.stringify(json))

            let proPic = pPic
            let tmpgDatar_i = JSON.parse(localStorage.getItem("patient"));
            let tmpgDatar = {
                ...tmpgDatar_i,
                patPicture: proPic,
            }
            localStorage.setItem("patient", JSON.stringify(tmpgDatar));

            // update the AuthContext
            dispatch({ type: 'LOGIN', payload: json })
        } else {
            return 'Error'
        }
    }

    return { gAuthlogin }
}

