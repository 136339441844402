import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
//import Backdrop from "@mui/material/Backdrop";
//import CircularProgress from "@mui/material/CircularProgress";

import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import { Link, useParams } from "react-router-dom";

import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Box,
} from "@mui/material";

import { useAuthContext } from "../../hooks/useAuthContext";

import "./Report.css";
import "./animate.css";

const Report = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [hwi, setHwi] = useState("");
  const [dpi, setDpi] = useState("");
  const [mai, setMai] = useState("");
  const [imi, setImi] = useState("");
  const [cwd, setCwd] = useState(true);
  const [pNotes, setPNotes] = useState("");

  const [users, setUsers] = useState(null);

  const [ptId, setPtId] = useState(null);

  const [hwiDos, setHwiDos] = useState([]);
  const [hwiDonts, setHwiDonts] = useState([]);
  const [imiDos, setImiDos] = useState([]);
  const [imiDonts, setImiDonts] = useState([]);
  const [dpiDos, setDpiDos] = useState([]);
  const [dpiDonts, setDpiDonts] = useState([]);
  const [maiDos, setMaiDos] = useState([]);
  const [maiDonts, setMaiDonts] = useState([]);

  let { id } = useParams();

  const { patient, dispatch } = useAuthContext();

  const tableCellStyles = {
    padding: "8px",
    width: "50%",
    listStyleType: "none",
    margin: "0",
  };

  const thstyles = {
    color: "#fff",
    padding: "7px 0",
    borderRadius: "20px",
    margin: "0 8px 8px 8px",
    position: "relative",
  };

  const thimgstyles = {
    margin: "0px auto",
    background: "#128183",
    borderRadius: "50px",
    padding: "10px",
  };

  const backgsyles = {
    background: "rgb(237 248 248)",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  };

  function Imagecomp({ srcimg, size, nameimg }) {
    return (
      <img
        style={{ margin: "0 auto" }}
        src={srcimg}
        alt={nameimg}
        width={size}
        height={size}
      />
    );
  }

  useEffect(() => {
    //console.log(id);
    const fetchUsers = async () => {
      const check = localStorage.getItem("patient");
      if (check) {
        setLoading(true);
        const response = await fetch(
          "/server/patient/getScoreCard/" + id + "/" + patient.patient._id,
          {
            headers: {
              Authorization: `Bearer ${patient.token}`,
            },
          }
        );
        const json = await response.json();

        // Get the Do's and Don'ts Regulatory record
        const bs_data = await fetch(
          "/server/patient/getBodySignalsReguls/body-signals-reguls",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${patient.token}`,
            },
          }
        );
        const bsd_json = await bs_data.json();
        // console.log(bsd_json)
        const rrr = bsd_json[0].regulatories;

        if (response.ok) {
          setUsers(json);
          //console.log(json);
        } else {
          navigate("/");
          // dispatch({ type: "LOGOUT", isAuthenticated: false });
        }

        let fullScoreCard = json.score;

        setPtId(json.userid);

        // console.log(json.score)
        let tmpQns = json.questions.length;

        let hwinnp = parseFloat(
          (16 * 10 * tmpQns) / Number(fullScoreCard.health_wellness_index.nnp)
        ).toFixed(4);
        let iinnp = parseFloat(
          (12 * 10 * tmpQns) / Number(fullScoreCard.immunity_index.nnp)
        ).toFixed(4);
        let dpinnp = parseFloat(
          (9 * 10 * tmpQns) / Number(fullScoreCard.disease_proneness_index.nnp)
        ).toFixed(4);
        let mainnp = parseFloat(
          (7 * 10 * tmpQns) /
            Number(fullScoreCard.metabolsim_assimilation_index.nnp)
        ).toFixed(4);
        setHwi(rangeCheck("", hwinnp));
        setDpi(rangeCheck("dis", dpinnp));
        setMai(rangeCheck("", mainnp));
        setImi(rangeCheck("", iinnp));

        let hwi_rprt = rangeCheck("", hwinnp);
        // console.log(hwi_rprt)
        const health_cont = [];
        if (
          hwi_rprt == "Immediate Care" ||
          hwi_rprt == "Poor" ||
          hwi_rprt == "Bad"
        ) {
          health_cont.push({ hwi_content: rrr[0].health });
        } else if (hwi_rprt == "Tipping Point") {
          health_cont.push({ hwi_content: rrr[1].health });
        } else if (
          hwi_rprt == "Good" ||
          hwi_rprt == "Excellent" ||
          hwi_rprt == "Amazing"
        ) {
          health_cont.push({ hwi_content: rrr[2].health });
        }

        let imi_rprt = rangeCheck("", iinnp);
        // console.log(imi_rprt)
        const immunity_cont = [];
        if (
          imi_rprt == "Immediate Care" ||
          imi_rprt == "Poor" ||
          imi_rprt == "Bad"
        ) {
          immunity_cont.push({ imi_content: rrr[0].immunity });
        } else if (imi_rprt == "Tipping Point") {
          immunity_cont.push({ imi_content: rrr[1].immunity });
        } else if (
          imi_rprt == "Good" ||
          imi_rprt == "Excellent" ||
          imi_rprt == "Amazing"
        ) {
          immunity_cont.push({ imi_content: rrr[2].immunity });
        }

        let dpi_rprt = rangeCheck("", dpinnp);
        // console.log(dpi_rprt)
        const disprop_cont = [];
        if (
          dpi_rprt == "Immediate Care" ||
          dpi_rprt == "Poor" ||
          dpi_rprt == "Bad"
        ) {
          disprop_cont.push({ dpi_content: rrr[0].disease_propensity });
        } else if (dpi_rprt == "Tipping Point") {
          disprop_cont.push({ dpi_content: rrr[1].disease_propensity });
        } else if (
          dpi_rprt == "Good" ||
          dpi_rprt == "Excellent" ||
          dpi_rprt == "Amazing"
        ) {
          disprop_cont.push({ dpi_content: rrr[2].disease_propensity });
        }

        let mai_rprt = rangeCheck("", mainnp);
        // console.log(mai_rprt)
        const metab_cont = [];
        if (
          mai_rprt == "Immediate Care" ||
          mai_rprt == "Poor" ||
          mai_rprt == "Bad"
        ) {
          metab_cont.push({ mai_content: rrr[0].metabolism });
        } else if (mai_rprt == "Tipping Point") {
          metab_cont.push({ mai_content: rrr[1].metabolism });
        } else if (
          mai_rprt == "Good" ||
          mai_rprt == "Excellent" ||
          mai_rprt == "Amazing"
        ) {
          metab_cont.push({ mai_content: rrr[2].metabolism });
        }

        setHwiDos(health_cont[0].hwi_content.dos);
        setHwiDonts(health_cont[0].hwi_content.donts);
        setImiDos(immunity_cont[0].imi_content.dos);
        setImiDonts(immunity_cont[0].imi_content.donts);
        setDpiDos(disprop_cont[0].dpi_content.dos);
        setDpiDonts(disprop_cont[0].dpi_content.donts);
        setMaiDos(metab_cont[0].mai_content.dos);
        setMaiDonts(metab_cont[0].mai_content.donts);

        function rangeCheck(d, val) {
          let allStatus = [
            "Immediate Care",
            "Poor",
            "Bad",
            "Tipping Point",
            "Good",
            "Excellent",
            "Amazing",
          ];
//          let allStatusDis = [
//            "Highly unlikely",
//            "More unlikely",
//            "Unlikely",
//            "Tipping Point",
//            "Likely",
//            "More likely",
//            "Highly likely",
//          ];
           let allStatusDis = [
             "Highly likely",
             "More likely",
             "likely",
             "Tipping Point",
             "UnLikely",
             "More unlikely",
             "Highly unlikely",
           ];
          let status = "";
          let n = 3;
          if (val > 2.0) {
            n = 0;
          } else if (val <= 2.0 && val >= 1.8183) {
            n = 1;
          } else if (val <= 1.8182 && val >= 1.59) {
            n = 2;
          } else if (val <= 1.6 && val >= 1.4287) {
            n = 3;
          } else if (val <= 1.4286 && val >= 1.1111) {
            n = 4;
          } else if (val <= 1.1111 && val >= 1.0527) {
            n = 5;
          } else if (val <= 1.0526 && val >= 1.0) {
            n = 6;
          }

          if (d != "dis") {
            status = allStatus[n];
          } else {
            status = allStatusDis[n];
          }
          if (json && json.connect_w_patient) {
            if (json && json.connect_w_patient != "yes") {
              setCwd(false);
            }
          } else {
            setCwd(false);
          }

          return status;
        }

        if (json.patient_notes) {
          let tnotes =
            '<tr class="tex-center bodyhead"><td style="display:inline-block;padding:5px 30px;font-size:18px">Expert Opinion</td></tr><tr><td><p>' +
            userName +
            ",</p><p>" +
            json.patient_notes +
            "</p></td></tr>";
          setPNotes(tnotes);
        }
        // setHwi(rangeCheck("", fullScoreCard.health_wellness_index.nnpsp_ratio));
        // setDpi(
        //   rangeCheck("dis", fullScoreCard.disease_proneness_index.nnpsp_ratio)
        // );
        // setMai(
        //   rangeCheck(
        //     "",
        //     fullScoreCard.metabolsim_assimilation_index.nnpsp_ratio
        //   )
        // );
        // setImi(rangeCheck("", fullScoreCard.immunity_index.nnpsp_ratio));

        setLoading(false);
      } else {
        setLoading(false);
        dispatch({
          type: "LOGOUT",
          isAuthenticated: false,
        });
      }
    };

    fetchUsers();
  }, []);

  function handleCwd() {
    console.log("connected");
  }

  function isEmpty(obj) {
    for (const property in obj) {
      return false;
    }
    return true;
  }

  const hwidostab = hwiDos && hwiDos.map((dd1, index1) => dd1);
  const hwidontstab = hwiDonts && hwiDonts.map((dd2, index2) => dd2);

  const imidostab = imiDos && imiDos.map((dd3, index3) => dd3);
  const imidontstab = imiDonts && imiDonts.map((dd4, index4) => dd4);

  const dpidostab = dpiDos && dpiDos.map((dd5, index5) => dd5);
  const dpidontstab = dpiDonts && dpiDonts.map((dd6, index6) => dd6);

  const maidostab = maiDos && maiDos.map((dd7, index7) => dd7);
  const maidontstab = maiDonts && maiDonts.map((dd8, index8) => dd8);

  const alldoos = [...hwidostab, ...imidostab, ...dpidostab, ...maidostab];
  const alldonts = [
    ...hwidontstab,
    ...imidontstab,
    ...dpidontstab,
    ...maidontstab,
  ];

  const noDupDoos = alldoos.filter(
    (item, index) => alldoos.indexOf(item) === index
  );
  const noDupDonts = alldonts.filter(
    (item1, index1) => alldonts.indexOf(item1) === index1
  );

  const tableData = [
    {
      id: 1,
      heading: "Health and Wellness",
      imgSrc: "/assets/images/health-wellness_icon_blue.png",
      Dos: noDupDoos,
      Donts: noDupDonts,
    },
  ];

  let userId = patient.patient._id;
  let userName = patient.patient.firstname;

  const myHTML =
    '<table class="partab"><tr><td><h1 class="text-upper tex-center ylwclr">This is the Only Body We Have</h1></td></tr><tr><td><p class="quote text-upper tex-center">Understand what it has to say</p></td></tr><tr><td><h3 class="text-upper tex-center ylwclr">Dear ' +
    userName +
    '</h3></td></tr><tr><td class="pdfp"><p>Based on your responses, we are pleased to share your body analysis. Please note, these general body signals are to be tracked regularly over longer periods. Hence it is advisable to take the assessment once every 60 days.</p></td></tr><tr><td class="pdfp"><p>We are in the process of developing an interactive app to help you track your health. Please stay tuned for further updates on the same.</p></td></tr><tr><td class="pdfp"><p>We look forward to a long standing partnership in building your health trajectory.</p></td></tr><tr><td class="pdfp ylwclr"><span>Note: For chronic conditions please consult your local patient before using this information.</span></td></tr></table><table width="100%" style="border-spacing:15px;padding:0; background: rgb(237 248 248); box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;">' +
    '<tr class="tex-center bodyhead"><td style="width:50%">What does this index mean?</td><td>' +
    userName +
    "<br /> you are at..</td></tr>" +
    '<tr class="secheading dark tex-center"><td colspan="2">Health & Wellness</td></tr><tr class="pointsback dark"><td><p style="margin-top:10px">This index measures a person\'s general well being at physical & psychological levels. This index indicates extent and level of ease with which the person carries routine chores & activities.</p><p style="margin-bottom:10px"><b style="text-decoration:underline;padding-bottom:5px">Key Index Attributes:</b><br>Quality of life, Strength, Stamina.</p></td><td style="padding:0 15px"><div class="imgandtex"><img class="animate__animated animate__fadeInLeft" src="/assets/images/health-wellness_icon.png" alt="Health & Wellness" /><p><b>' +
    hwi +
    '</b></p></div><div class="infoarrow" style=" display: flex; justify-content: space-between; padding: 0;"><p style=" list-style: none;">Immediate <br>  Care</p><p>Tipping <br> Point</p><p>Amazing</p></div><div class="arrow ' +
    hwi +
    '" ></div></td></tr><tr class="secheading dark tex-center"><td colspan="2">Immunity</td></tr><tr class="pointsback dark"><td><p style="margin-top:10px">This index measures a person\'s resistance level to diseases, their endurance when affected by a disease and, the time they take to recover with minimal to no internal damage.</p><p style="margin-bottom:10px"><b style="text-decoration:underline;padding-bottom:5px">Key Index Attributes:</b><br>Resistance to Diseases, Impact of Disease, Time to Recover.</p></td><td style="padding:0 15px"><div class="imgandtex"><img class="animate__animated animate__fadeInLeft" src="/assets/images/immunity_icon.png" alt="Immunity" /><p><b>' +
    imi +
    '</b></p></div><div class="infoarrow" style=" display: flex; justify-content: space-between; padding: 0;"><p style=" list-style: none;">Immediate <br>  Care</p><p>Tipping <br> Point</p><p>Amazing</p></div><div class="arrow ' +
    imi +
    '" ></div></td></tr><tr class="secheading dark tex-center"><td colspan="2">Disease Propensity</td></tr><tr class="pointsback dark"><td><p style="margin-top:10px">The index monitors person\'s body to determine near term and long-term susceptibility to frequent infections, diseases and long drawn recovery timeframes.</p><p style="margin-bottom:10px"><b style="text-decoration:underline;padding-bottom:5px">Key Index Attributes:</b><br>Susceptibility, Type of Diseases, Recovery Period</p></td><td style="padding:0 15px"><div class="imgandtex"><img class="animate__animated animate__fadeInLeft" src="/assets/images/disease_icon.png" alt="Disease Propensity" /><p><b>' +
    dpi +
    '</b></p></div><div class="infoarrow" style=" display: flex; justify-content: space-between; padding: 0;"><p style="margin:0px 0 0 -25px; list-style: none;">Highly<br>likely</p><p>Tipping<br>Point</p><p>Higly<br>unlikely</p></div><div  class="arrow ' +
    dpi +
    '" ></div></td></tr><tr class="secheading dark tex-center"><td colspan="2">Metabolism</td></tr><tr class="pointsback dark"><td><p style="margin-top:10px">This index is focussed on digestion and assimilation. It monitors parameters like complexion changes, physique, sleep, impact of aging, tolerance to adverse climate conditions.</p><p style="margin-bottom:10px"><b style="text-decoration:underline;padding-bottom:5px">Key Index Attributes:</b><br>Digestive system and Assimilation ability.</p></td><td style="padding:0 15px"><div class="imgandtex"><img class="animate__animated animate__fadeInLeft" src="/assets/images/metabolism_icon.png" alt="Metabolism" /><p><b>' +
    mai +
    '</b></p></div><div class="infoarrow" style=" display: flex; justify-content: space-between; padding: 0;"><p style=" list-style: none;">Immediate <br>  Care</p><p>Tipping <br> Point</p><p>Amazing</p></div><div class="arrow ' +
    mai +
    '" ></div></td></tr></table>';
  // '<table width="100%" class="fotpart">' + pNotes + '<tr><td class="tex-center">If you have any questions please send them to:<a class="tex-white" href="mailto: AskTheExperts@ayana.ai">AskTheExperts@ayana.ai</a></td></tr><tr><td><h4 class="tex-center" style="color:#72b4d6">Powered by Ayana</h4></td></tr></table>';

  return (
    <>
      <div className="app">
        <div className="centered-div ques">
          <Card className="cardstyle">
            <div dangerouslySetInnerHTML={{ __html: myHTML }} />
            {/* cwd ? (
              ""
            ) : (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  margin: "15px 0",
                }}
              >
                <Button
                  style={{
                    background: "#1A8585",
                    color: "white",
                    textTransform: "none",
                  }}
                  onClick={handleCwd}
                >
                  Connect With a patient - Coming Soon!
                </Button>{" "}
              </div>
            ) */}

            <Box
              component={Paper}
              sx={{ pl: 0, pr: 0 }}
              style={{
                maxWidth: "600px",
                margin: "0 auto",
                background: "rgba(239, 255, 252, 64%)",
                padding: "0 15px 15px 15px",
                ...backgsyles,
              }}
            >
              <ul style={{ padding: 0, display: "flex" }}>
                <li
                  style={{
                    textAlign: "center",
                    width: "50%",
                    margin: "0",
                    listStyleType: "none",
                  }}
                >
                  <Typography style={{ background: "#138085", ...thstyles }}>
                    Do's - What will help
                  </Typography>
                </li>
                <li
                  style={{
                    textAlign: "center",
                    width: "50%",
                    margin: "0",
                    listStyleType: "none",
                  }}
                >
                  <Typography
                    style={{ background: "rgb(222 77 70)", ...thstyles }}
                  >
                    Dont's - (Refrain or Avoid)
                  </Typography>
                </li>
              </ul>

              {tableData.map((row) => (
                <ul key={row.id} style={{ padding: 0, display: "flex" }}>
                  <li style={{ background: "#c1f4f7", ...tableCellStyles }}>
                    <ul style={{ padding: "30px" }}>
                      {row.Dos.map((dosItem, index) => (
                        <li style={{ padding: "5px 0" }} key={index}>
                          {dosItem}
                        </li>
                      ))}
                    </ul>
                  </li>

                  <li style={{ background: "#F6E4E4", ...tableCellStyles }}>
                    <ul style={{ padding: "30px" }}>
                      {row.Donts.map((dontItem, index) => (
                        <li style={{ padding: "5px 0" }} key={index}>
                          {dontItem}
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              ))}
            </Box>

            {/* {loading ? (
                            <Backdrop
                                sx={{
                                    color: "#fff",
                                    zIndex: (theme) => theme.zIndex.drawer + 1000,
                                }}
                                open={true}
                            >
                            </Backdrop>
                        ) : (
                            ""
                        )} */}
          </Card>
        </div>
      </div>
    </>
  );
};

export default Report;
