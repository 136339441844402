import React from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";
//import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import jwtDecode from "jwt-decode";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Alert } from "@mui/material";

import { useAuthContext } from "../hooks/useAuthContext";

// const useStyles = makeStyles((theme) => ({
//     homeGrid: {
//         minWidth: "320px",
//     },
//     icon: {
//         marginRight: theme.spacing(2),
//     },
//     heroContent: {
//         backgroundColor: theme.palette.background.paper,
//         padding: theme.spacing(8, 0, 6),
//     },
//     heroButtons: {
//         marginTop: theme.spacing(4),
//     },
//     cardGrid: {
//         paddingTop: theme.spacing(8),
//         paddingBottom: theme.spacing(8),
//     },
//     card: {
//         height: "100%",
//         display: "flex",
//         flexDirection: "column",
//     },
//     cardMedia: {
//         paddingTop: "56.25%", // 16:9
//     },
//     cardContent: {
//         flexGrow: 1,
//     },
//     footer: {
//         backgroundColor: theme.palette.background.paper,
//         padding: theme.spacing(6),
//     },
//     ".wellness": {
//         backgroundColor: "#D1E9FC",
//     },
//     qnbutton: {
//         textDecoration: "none",
//     },
//     conBtn: {
//         border: "1px solid rgb(26 133 133)",
//         color: "#1a8585",
//         textTransform: "capitalize !important",
//     },
//     error: {
//         borderBottom: "1px solid #ff0000",
//     },
//     errorDisplayText: {
//         backgroundColor: "rgb(253, 236, 234)",
//         borderRadius: "4px",
//         padding: "6px 16px",
//         marginTop: "10px",
//     },
//     successDisplayText: {
//         backgroundColor: "#a5ff6d",
//         borderRadius: "4px",
//         padding: "6px 16px",
//         marginTop: "10px",
//     },
// }));

export default function UserProfile() {
  const { patient } = useAuthContext();

  const navigate = useNavigate();

  const [gender, setGender] = useState("");
  const [ageGroup, setAgeGroup] = useState("");
  const [geoLoc, setGeoLoc] = useState("");
  // const [areYouPositive, setAreYouPositive] = useState('')
  const [covidAffect, setCovidAffect] = useState("");

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);

  // const classes = useStyles();

  useEffect(() => {
      
    patient.patient.gender ? navigate("/welcome"):"";
    let tmpgData2 = JSON.parse(localStorage.getItem("loginData"));
    const fetchPatient = async () => {
      const response = await fetch(
        "/server/patient/getPatient/" + patient.patient._id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${patient.token}`,
          },
        }
      );

      const json = await response.json();

      setGender(json.gender);
      setAgeGroup(json.age_group);
      setGeoLoc(json.geo_location);
      setCovidAffect(json.covid_affect);

      setError(null);
      setEmptyFields([]);
    };

    fetchPatient();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault()

    const ptDets = {
      age_group: ageGroup,
      gender: gender,
      geo_location: geoLoc,
      covid_affect: covidAffect,
      psIden: patient.patient._id,
    };
    const response_qt = await fetch("/server/patient/updateDetsOfPats", {
      method: "POST",
      body: JSON.stringify(ptDets),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${patient.token}`,
      },
    });
    const json_qt = await response_qt.json();

    if (!response_qt.ok) {
      setError(json_qt.error);
      setEmptyFields(json_qt.emptyFields);
    } else {
      //console.log(response_qt);
      setError(null);
      setEmptyFields([]);
      //navigate("/Questionnaire");
      setSuccess("Successfully Updated!");

      //console.log(json_qt.gender)
      let tmpgDatar_i = patient.patient;
      let pgender = json_qt.gender;
      let tmpgDatar = {
        patient: {
          ...tmpgDatar_i,
          gender: pgender,
        },
      };
      localStorage.setItem("patient", JSON.stringify(tmpgDatar))

      // navigate(-1)
      window.location.reload(false);
    }
  };

  //console.log(process.env.REACT_APP_GOOGLE_CLIENT_ID);
  return (
    <div>
      {
        <>
          <Container>
            <Typography variant="h6" gutterBottom>
              My Profile
            </Typography>

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label5">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label5"
                      id="demo-simple-select"
                      value={gender}
                      label="Gender"
                      onChange={(e) => setGender(e.target.value)}
                      className={
                        (emptyFields || "").includes("gender") ? "error" : ""
                      }
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label4">
                      Age Group
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label4"
                      id="demo-simple-select"
                      value={ageGroup}
                      label="Age Group"
                      onChange={(e) => setAgeGroup(e.target.value)}
                      className={
                        (emptyFields || "").includes("age_group") ? "error" : ""
                      }
                    >
                      <MenuItem value={"18-28"}>18-28</MenuItem>
                      <MenuItem value={"29-38"}>29-38</MenuItem>
                      <MenuItem value={"39-49"}>39-49</MenuItem>
                      <MenuItem value={"50-60"}>50-60</MenuItem>
                      <MenuItem value={"61-70"}>61-70</MenuItem>
                      <MenuItem value={"70+"}>70+</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label3">
                      Geo Location
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label3"
                      id="demo-simple-select"
                      value={geoLoc}
                      label="Geo Location"
                      onChange={(e) => setGeoLoc(e.target.value)}
                      className={
                        (emptyFields || "").includes("geo_location")
                          ? "error"
                          : ""
                      }
                    >
                      <MenuItem value={"South India"}>South India</MenuItem>
                      <MenuItem value={"West India"}>West India</MenuItem>
                      <MenuItem value={"East India"}>East India</MenuItem>
                      <MenuItem value={"Central India"}>Central India</MenuItem>
                      <MenuItem value={"USA (East Coast)"}>
                        USA (East Coast)
                      </MenuItem>
                      <MenuItem value={"USA (West Coast)"}>
                        USA (West Coast)
                      </MenuItem>
                      <MenuItem value={"USA (Central)"}>USA (Central)</MenuItem>
                      <MenuItem value={"Coastal belt"}>Coastal belt</MenuItem>
                      <MenuItem value={"Hot and arid belt"}>
                        Hot and arid belt
                      </MenuItem>
                      <MenuItem value={"Cold and humid belt"}>
                        Cold and humid belt
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label1">
                      Were you affected by covid?
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label1"
                      id="demo-simple-select"
                      value={covidAffect}
                      label="Affected by covid?"
                      onChange={(e) => setCovidAffect(e.target.value)}
                      className={
                        (emptyFields || "").includes("covid_affect")
                          ? "error"
                          : ""
                      }
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={12} xs={12}>
                  <Button
                    type="submit"
                    variant="outlined"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
          {error && (
            <Alert severity="error">
              <div className="error">{error}</div>
            </Alert>
          )}
          {success && <Alert severity="success">{success}</Alert>}
        </>
      }
    </div>
  );
}
