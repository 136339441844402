import React from 'react'
import { useState } from "react"
import Typography from '@mui/material/Typography/Typography';
import Box from '@mui/material/Box';
import {
  Alert
} from "@mui/material";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import jwtDecode from "jwt-decode";

import { useGauth } from "./hooks/useGauth"

const Home = () => {

  const { gAuthlogin } = useGauth()
  const [success, setSuccess] = useState(null)

  const handleLogin = async (credentialResponse) => {
    //console.log(credentialResponse)
    var userObject = jwtDecode(credentialResponse.credential);
    //let tmpgData = JSON.parse(userObject);

    //console.log(userObject)
    gAuthlogin(userObject.given_name, userObject.family_name, userObject.email, userObject.sub, userObject.picture)
    const guser = await gAuthlogin(userObject.given_name, userObject.family_name, userObject.email, userObject.sub, userObject.picture)
      
    if(guser == "Error") {
        setSuccess('Sorry, you cannot log in here. You are being redirected to Knome.')
        setTimeout(() => {
            window.location.href = "https://me.knome.health/"
        }, 5000)
    }
  }

  return (
    <>
      <Box elevation={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', p: 5 }}>
        <Typography component="h1" variant="h4" align="center" color="textPrimary">Welcome to Ayurmd</Typography>
        <Typography variant="h6" align="center" color="textSecondary" paragraph>A DIGITAL HEALTH COMPANY</Typography>
        <img src='/ayurmdLogoWhite.png' alt='Ayurmd' title='Ayurmd' />
      </Box>

      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <GoogleOAuthProvider
          //clientId={"667259907049-pje0jb7rrpke9jtil6uefc46pk2lv4sl.apps.googleusercontent.com"}
          clientId={"1050265659815-gd8vislgd70sr8c8sdbf94h58l5j3gg0.apps.googleusercontent.com"}
        >
          <GoogleLogin
            width="260px"
            size="large "
            onSuccess={(credentialResponse) => {
              // console.log(credentialResponse);
              handleLogin(credentialResponse);
            }}
            onError={() => {
              console.log("Login Failed");
              //handleFailure();
            }}
          />
        </GoogleOAuthProvider>
        {success && <Alert severity="warning">{success}</Alert>}
      </Box>

    </>
  )
}

export default Home
